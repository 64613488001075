<script  setup>
// const { config, fetchConfig } =  useConfig();
const { cached_data } = await useSettings("Footer")
const {handleAnimation} = useUIControl()
const footers = import.meta.glob("@/assets/images/footers/*.svg", {
  query: '?raw', import: 'default',
  eager: true,
});
const animation_target = ref(null)
onMounted(() => {

  if(process.client)handleAnimation(animation_target, { type: 'footer' });
  
})
</script>
<template>
  <footer class="footer" ref="animation_target">
    <div
      class="footer__inner 2xl:container flex text-[12px] md:text-base  flex-row justify-between mx-auto py-8 lg:grid lg:grid-cols-12 lg:gap-8 lg:items-end"
    >
     

      <FooterSection
        v-for="section in cached_data.footer"
        :blok="section"
        :key="section._uid"
      />
    </div>
    <div
      class="footer__background"
      v-html="footers['/assets/images/footers/footer_new_02.svg']"
    ></div>
  </footer>
</template>
<style lang="scss">
.footer {
  margin: 0px auto;
  color: #000;
  // background-color: $c-purple-100;
 padding: 2rem 24px;
  // font-size: 14px;
  &__background {
    pointer-events: none;
    position: absolute;
    bottom: 0px;
    min-height: 100%;
    width: 100%;
    z-index: -1;
    left: 0px;
    // display: none;
    // svg {
    //   max-width: 100%;
    //   height: auto;
    //   overflow: visible;
    // }
    svg{
      position: absolute;
      bottom:0px;
      height:150%;
      overflow: visible;
      max-width: 100%;
      width: 100%;
      // transform: translateX();
    }
    // overflow-x: hidden;
     @include breakpoint-max(md) {
        // max-wi
        display:flex;
        justify-content: flex-start;
     }
  }
  @include breakpoint(md) {
    // padding: 1rem 24px;
    // margin-top: -30rem;
  }
  @include breakpoint(lg) {
    padding: 2rem 2rem;
    margin-top: -30rem;
  }
  &__inner {
     .footer-section {
      a{
        &:hover{
          color: #fff;
        }
      }
        &:first-child{
          display: flex;
          // flex-direction: ;
          flex-direction: column-reverse;
          .sb-pagelink{
            position: relative;
            margin-bottom: 0px;
           &:first-child{
               margin-top:1.2rem;
              //  &::after {
              //   content: "";
              //   pointer-events: none;
              //   width: 4rem;
              //   height: 1px;
              //   display: block;
              //   position: absolute;
              //   // margin:auto;
              //   // vertical-align: middle;
              //   width: 100%;
              //   left:0px;
              //   top:-1rem;
              //   background-color: $c-green-40;
              // }
            }
          }
          
        }
          margin-bottom:1rem;
     }
     @include breakpoint-max(sm) {
        
     }
     @include breakpoint(sm) {
      // background-color:hotpink;
       .footer-section {
          
        }
     }
     @include breakpoint(md) {
      // background-color:hotpink;
       .footer-section {
          &:first-child{
             .sb-pagelink{
              position: relative;
            
               margin-right:1rem;
            
           }
            grid-column: 2 / span 7;
            justify-self: flex-start;
            // flex-direction: row;
          }
          &:last-child{
            grid-column: 9 / span 3;
            justify-self: end;
          }
        }
     }
     @include breakpoint(xl) {
      // background-color:hotpink;
       .footer-section {
          &:first-child{
             .sb-pagelink{
              
              &:first-child{

                margin-top:0px;
                // &::after{
                //   display: inline-block;
                //   vertical-align: middle;
                //   top:-.1em;
                //   left:1rem;
                //   width: 4rem;
                //   margin-right: 1rem;
                //   position: relative;
                // }
              }
              &:not(:last-child){
                margin-right:2.5rem;
                &::after{
                  content:"";
                  position: absolute;
                  width:4px;
                  border-radius: 2px;
                  aspect-ratio: 1 / 1;
                  background-color: #000;
                  right:-1.4rem;
                  display: inline-block;
                  top:calc(50% - 2px);
                }
              }
            
           }
            grid-column: 2 / span 7;
             flex-direction: row;
          }
          &:last-child{
            grid-column: 9 / span 3;
            justify-self: end;
          }
        }
     }
  }
  
}
</style>